<template>
   <QBtnDropdown color="primary" :label="aggLevelLabel">
      <QList>
         <QItem clickable v-close-popup @click="selectAggLevel('24h')">
            <QItemSection>
               <QItemLabel>24h</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('12h')">
            <QItemSection>
               <QItemLabel>12h </QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('6h')">
            <QItemSection>
               <QItemLabel>6h</QItemLabel>
            </QItemSection>
         </QItem>
      </QList>
   </QBtnDropdown>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useSessionMetricsStore } from "../stores/sessionMetricsStore"
const sessionMetricsStore = useSessionMetricsStore()
import { constants } from "../utils/index"

const aggLevelLabel = computed(() => {
   if (sessionMetricsStore.agg_level) {
      return sessionMetricsStore.agg_level
   } else {
      return "Loading..."
   }
})
const selectAggLevel = async (aggLevel: string) => {
   if (aggLevel === "24h") {
      sessionMetricsStore.agg_level! = "24h"
   } else if (aggLevel === "12h") {
      sessionMetricsStore.agg_level! = "12h"
   } else if (aggLevel === "6h") {
      sessionMetricsStore.agg_level! = "6h"
   } else if (aggLevel === "3h") {
      sessionMetricsStore.agg_level! = "3h"
   } else if (aggLevel === "1h") {
      sessionMetricsStore.agg_level! = "1h"
   } else {
      throw new Error("Unsupported aggregation level")
   }
   const daysBack = sessionMetricsStore.daysBack!
   const aggLevelForQuery = sessionMetricsStore.agg_level
      ? sessionMetricsStore.agg_level
      : constants.DEFAULT_AGG_LEVEL
   await sessionMetricsStore.getSessionMetrics(daysBack, aggLevelForQuery)
}
</script>
