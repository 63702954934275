<template>
   <div>
      <div class="chart-container">
         <Line
            id="chart"
            ref="line"
            :options="chartOptions"
            :data="chartData"
            height="200px"
            fullWidth
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, onUnmounted, VNodeRef, computed } from "vue"
import { Line } from "vue-chartjs"
import { enUS } from "date-fns/locale"
import { useDashboardStore } from "../../stores/dashboardStore"
import { createNTileVsDefaultDatasets } from "../../utils/dashboardDatasetGenerators"

import {
   Chart as ChartJS,
   Tooltip,
   LineElement,
   ChartData,
   PointElement,
   TimeScale,
   LinearScale,
   ChartOptions,
   Filler,
   Legend,
   Title,
} from "chart.js"

ChartJS.register(
   Tooltip,
   LineElement,
   TimeScale,
   LinearScale,
   PointElement,
   Filler,
   Legend,
   Title,
)

import "chartjs-adapter-date-fns"

const tooltipEnabled = ref(true)

const dashboardStore = useDashboardStore()

const chartOptions: Ref<ChartOptions<"line">> = computed(() => ({
   responsive: true,
   interaction: {
      mode: "index",
      intersect: false,
   },
   elements: {
      line: {
         tension: 0.4,
      },
   },
   scales: {
      y: {
         ticks: {
            // Include a percentage sign in the ticks
            callback: function (tickValue: string | number) {
               return Math.abs(Number(tickValue)).toFixed(2) + "%"
            },
         },
         position: "right",
         grid: {
            display: true,
            lineWidth: (context) => {
               const length = context.scale.ticks.length
               if (context.index === 0 || context.index === length - 1) {
                  return 0
               }
               return 1
            },
         },
      },
      x: {
         display: true,
         grid: {
            display: false,
         },
         type: "time",
         time: {
            unit: "day",
            round: "day",
            // tooltipFormat: "MMM d HH:00",
         },
         adapters: {
            date: {
               locale: enUS,
            },
         },
         ticks: {
            source: "auto",
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
         },
      },
   },
   plugins: {
      filler: {
         drawTime: "beforeDatasetsDraw",
         propagate: true,
      },
      legend: {
         display: true,
         position: "left",
         maxWidth: 150,
         labels: {
            boxWidth: 10,
         },
         onClick: () => {
            // Prevent the default action
            return
         },
      },
      title: {
         display: true,
         text: "Top 50th Percentile Conversion Rate",
      },
      tooltip: {
         enabled: tooltipEnabled.value,
         padding: 10,
         caretPadding: 10,
         itemSort: (a, b) => {
            const aValue = a.parsed.y
            const bValue = b.parsed.y
            return bValue - aValue
         },
         callbacks: {
            title: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  month: "long",
                  day: "numeric",
               }
               return date.toLocaleString(undefined, options)
            },
            label: function (context) {
               var label = context.dataset.label || ""

               if (label) {
                  label += ": "
               }
               if (context.parsed.y !== null) {
                  label += Math.abs(context.parsed.y) + "%"
               }
               return label
            },
            beforeBody: () => {
               return "Conversion Rates:"
            },
            afterBody: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  hour: "numeric",
                  timeZoneName: "short",
               }
               return `As of ${date.toLocaleString(undefined, options)}`
            },
         },
      },
   },
}))

const chartData: Ref<ChartData<"line">> = ref({
   labels: [],
   datasets: [],
})

const line: VNodeRef = ref(null)

const handleResize = () => {
   if (!line.value) return
   line.value.chart.resize()
}

onMounted(() => {
   window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
   window.removeEventListener("resize", handleResize)
})

dashboardStore.$subscribe(() => {
   updateChartData()
   line.value.chart.update()
})

const updateChartData = () => {
   let labels: string[] = dashboardStore.time_series!.dt

   const datasets = createNTileVsDefaultDatasets()
   if (!datasets) {
      throw new Error("No datasets to render")
   }

   chartData.value = {
      labels: labels,
      datasets: datasets,
   }
}
updateChartData()
</script>
<style lang="scss" scoped>
.chart-container {
   position: relative;
   height: auto;
   width: 100%;
}
#chart {
   position: relative;
   height: 100%;
   width: 100%;
}
</style>
