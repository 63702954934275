<template>
   <div class="bg-white q-pa-md" id="page-container">
      <div id="main-container">
         <h1 class="text-left text-h4">Project Settings</h1>
         <p id="project-id">
            <span>Project Id: </span>{{ projectStore.projectId }}
         </p>
         <QCard>
            <QTabs
               v-model="tab"
               dense
               class="text-grey"
               active-color="primary"
               indicator-color="primary"
               align="justify"
               narrow-indicator
            >
               <QTab name="settings" label="Settings" />
               <QTab name="install" label="Install" />
            </QTabs>

            <QSeparator />

            <QTabPanels v-model="tab" animated>
               <QTabPanel name="settings">
                  <QForm
                     id="project-settings-edit-form"
                     @submit="openSaveDialog"
                  >
                     <QField
                        v-model="holdback"
                        label="Holdback Percentage"
                        borderless
                        hint="The percentage of sessions that will receive all default values."
                     >
                        <q-slider
                           id="holdback-slider"
                           v-model="holdback"
                           :min="0"
                           :max="100"
                           :step="5"
                           snap
                           label
                           :label-value="holdback + '%'"
                           label-always
                           markers
                           title="Holdback Percentage"
                           color="primary"
                        />
                     </QField>
                     <h5 class="text-left q-ma-none q-mt-md">
                        Optimization Metric
                     </h5>
                     <div id="strategy-select">
                        <QSelect
                           v-model="selectedStrategy"
                           :options="optimizationMetricOptions"
                           disable
                           label="Optimization Goal"
                           hint="Optimize for sessions with at least one reward event"
                           required
                        ></QSelect>
                     </div>
                     <h5 class="text-left q-ma-none q-mt-md">Visual Editor</h5>
                     <div id="default-visual-editor-url">
                        <QInput
                           v-model="defaultVisualEditorUrl"
                           label="Default Visual Editor URL"
                           hint="The URL the visual editor will use by default"
                           :rules="[
                              (val) =>
                                 val?.startsWith('http') ||
                                 'URL must start with http',
                           ]"
                        />
                     </div>
                     <div id="highlight-color">
                        <QInput
                           v-model="highlightColor"
                           label="Element Highlight Color"
                           hint="We support HEX, RGB, and RGBA color formats. Example: #ff0000, rgb(255, 0, 0), or the default: rgba(158, 66, 245, 0.5)"
                           :rules="[
                              (val) =>
                                 val?.startsWith('#') ||
                                 val?.startsWith('rgb') ||
                                 'Color must start with # or rgb',
                           ]"
                        />
                     </div>
                     <div id="action-row" class="row justify-end">
                        <Protect :role="'org:_admin'">
                           <QBtn
                              id="deleteProject"
                              color="red"
                              @click="onDeleteProject"
                              class="action-button"
                              >Delete</QBtn
                           >
                           <QBtn
                              id="renameProject"
                              color="info"
                              @click="onRenameProject"
                              class="action-button"
                              >Rename</QBtn
                           >
                        </Protect>
                        <QBtn
                           id="submit"
                           type="submit"
                           color="primary"
                           class="action-button"
                           :disable="showDisabledSubmit"
                           >Update</QBtn
                        >
                     </div>
                  </QForm>
                  <div id="after-form">
                     <RouterLink id="org-home-link" :to="{ name: 'OrgHome' }"
                        >Looking for organization settings?</RouterLink
                     >
                  </div>
               </QTabPanel>
               <QTabPanel name="install">
                  <CodeSnippets :header="false" :flat="true" />
               </QTabPanel>
            </QTabPanels>
         </QCard>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QForm, QBtn, QSlider, useQuasar, QField } from "quasar"
import { ref, computed, Ref } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { useRouter } from "vue-router"
import { toast } from "vue3-toastify"
import { OptimizationMetric } from "../types"
import { getOrg } from "@/services/clerk"
import { CodeSnippets } from "@components/index"
import { Protect } from "vue-clerk"

type SelectOption = {
   label: string
   value: string
   description: string
}
const $q = useQuasar()
const projectStore = useProjectStore()
const router = useRouter()
const localProjectSettings = await projectStore.getProjectSettings(
   projectStore.projectId!,
)
const tab = ref<string>("settings")
const holdback = ref<number>(localProjectSettings.settings.holdbackPercentage)
const defaultOptimizationMetric = {
   label: "Binary Reward",
   value: "binaryReward",
   description: "Maximize for sessions with at least one reward event",
}
const defaultVisualEditorUrl = ref(
   localProjectSettings.settings.visualEditor?.defaultUrl,
)
const highlightColor = ref(
   localProjectSettings.settings.visualEditor?.highlightColor,
)
if (!highlightColor.value) {
   highlightColor.value = "rgba(158, 66, 245, 0.5)"
}
if (!localProjectSettings.settings.visualEditor?.highlightColor) {
   if (localProjectSettings.settings.visualEditor) {
      localProjectSettings.settings.visualEditor.highlightColor =
         "rgba(158, 66, 245, 0.5)"
   } else {
      localProjectSettings.settings.visualEditor = {
         defaultUrl: "",
         highlightColor: "rgba(158, 66, 245, 0.5)",
      }
   }
}

const optimizationMetricOptions: Array<SelectOption> = [
   defaultOptimizationMetric,
   {
      label: "Count of Reward Events",
      value: "rewardCount",
      description:
         "Maximize the number of reward events that occurred in the session",
   },
   {
      label: "Reward Total",
      value: "totalReward",
      description:
         "Maximize total of all rewards across all reward events in a session",
   },
   // {
   //    label: "Time spent on page",
   //    value: "totalEngagement",
   //    description: "the total time spend on the page per session",
   // },
]

const startingStrategy = () => {
   // Since we only support Binary Reward right now, always
   // set the starting strategy to that

   return defaultOptimizationMetric

   // This would be the code to use if we supported multiple optimization metrics
   // if (!localProjectSettings.settings.optimizationMetric) {
   //    return defaultOptimizationMetric
   // }
   // return defaultOptimizationMetric
   // const match = _.find(optimizationMetricOptions, {
   //    value: localProjectSettings.settings.optimizationMetric.strategy,
   // })
   // if (match) {
   //    return match
   // } else {
   //    return defaultOptimizationMetric
   // }
}
const selectedStrategy = ref<SelectOption>(startingStrategy())
const strategyKey = computed(() => {
   if (selectedStrategy.value != null) {
      return selectedStrategy.value.value
   } else {
      return defaultOptimizationMetric.value
   }
})
const optimizationMetric: Ref<OptimizationMetric> = computed(() => {
   return {
      strategy: strategyKey.value,
   }
})

const openSaveDialog = () => {
   $q.dialog({
      title: "Save Project Settings",
      message: "Are you sure you want to save these project settings?",
      cancel: true,
   }).onOk(async () => {
      await projectStore.updateProjectSettings(projectStore.projectId!, {
         settings: {
            holdbackPercentage: holdback.value,
            optimizationMetric: optimizationMetric.value,
            visualEditor: {
               defaultUrl: defaultVisualEditorUrl.value!,
               highlightColor: highlightColor.value!,
            },
            rewards: localProjectSettings.settings.rewards,
         },
      })
      router.push({ name: "ProjectSettings" }).then(() => {
         toast.success("Project Settings updated")
      })
   })
}

const onDeleteProject = () => {
   $q.dialog({
      title: "Delete Project",
      message:
         "Are you sure you want to delete this project? This cannot be undone.",
      cancel: true,
   }).onOk(async () => {
      await projectStore.deleteProject(projectStore.projectId!)
      // await projectStore.getActiveOrgProjects()
      await router.push({
         name: "OrgHome",
      })
      toast.success("Project deleted")
   })
}

const defaultNewHumanReadableName = ref("")

const onRenameProject = () => {
   $q.dialog({
      title: "Rename Project",
      message: "Enter the new name for the project",
      prompt: {
         model: defaultNewHumanReadableName.value,
         type: "text",
      },
      cancel: true,
   }).onOk(async (humanReadableName: string) => {
      await projectStore.updateCurrentProject(humanReadableName)
      const org = await getOrg()
      if (!org) {
         throw new Error("Org is undefined")
      }
      router
         .push({
            name: "ProjectHome",
            params: { projectId: projectStore.projectId, orgSlug: org.slug },
         })
         .then(() => {
            toast.success("Project renamed")
         })
   })
}

const showDisabledSubmit = computed(() => {
   return (
      localProjectSettings.settings.holdbackPercentage === holdback.value &&
      localProjectSettings.settings.visualEditor?.defaultUrl ==
         defaultVisualEditorUrl.value &&
      localProjectSettings.settings.visualEditor?.highlightColor ==
         highlightColor.value
   )
})
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
   max-width: 600px;
}
h3 {
   margin: 0;
}
#project-settings-edit-form {
   padding: 1em;
   margin-bottom: 1em;
   width: 100%;
}
.q-field {
   margin-bottom: 10px;
}
#action-row {
   margin-top: 25px;
}
#holdback-slider {
   padding-top: 40px;
}
#strategy-select {
   width: 200px;
}
.action-button {
   margin-left: 10px;
}
#after-form {
   width: 100%;
   font-style: italic;
   text-align: left;
   a {
      color: $grey-9;
   }
}
#project-id {
   text-align: left;
   width: 100%;
   span {
      font-weight: bold;
   }
}
#page-container {
   min-height: 80vh;
}
</style>
