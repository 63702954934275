<template>
   <QCard class="graph-card">
      <QCardSection class="bg-primary">
         <div class="text-h5 text-white">Variable Snapshot</div>
      </QCardSection>
      <QCardSection class="col" horizontal>
         <div id="selector-container">
            <QBtnDropdown
               color="primary"
               :label="activeVariableFilter.humanReadableName"
               outline
            >
               <QList>
                  <template
                     v-for="variable in projectStore.variables"
                     v-bind:key="variable.id"
                  >
                     <QItem
                        clickable
                        v-close-popup
                        @click="onVariableClick(variable.id)"
                     >
                        <QItemSection>
                           <QItemLabel>{{
                              variable.humanReadableName
                           }}</QItemLabel>
                        </QItemSection>
                     </QItem>
                  </template>
               </QList>
            </QBtnDropdown>
         </div>
      </QCardSection>

      <QCardSection class="col" horizontal>
         <QTable
            class="col-8"
            id="combinations-table"
            v-if="dashboardStore.hasVariants && projectStore.variables"
            dense
            :rows="rows"
            :columns="columns"
            row-key="variant"
            width="100%"
            :pagination="{
               page: 1,
               rowsPerPage: 10,
               descending: false,
            }"
            :hide-pagination="true"
            flat
         >
            <template v-slot:header-cell-conversion-rate="props">
               <QTh :props="props">
                  {{ conversionRateColumnLabel }}
               </QTh>
            </template>
            <template v-slot:no-data="{ icon, filter }">
               <div
                  id="no-data-container"
                  class="full-width row flex-center text-accent q-ma-lg"
               >
                  <QIcon
                     size="2em"
                     color="primary"
                     :name="filter ? 'filter_b_and_w' : icon"
                  />
                  <span id="no-data"
                     >No data match your query. Please change your filters and
                     try again.</span
                  >
               </div>
            </template>
         </QTable>
         <QSeparator vertical></QSeparator>
         <SnapshotDoughnut ref="doughnut" class="col-4"></SnapshotDoughnut>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import { QTable, QTableColumn } from "quasar"
import { useDashboardStore } from "@stores/dashboardStore"
import { useProjectStore } from "@stores/projectStore"

import { formatPercentTwoDecimals } from "./formatters"
import { VariantForRender } from "@/types/variable-snapshot"
import SnapshotDoughnut from "@components/variable-snapshot/SnapshotDoughnut.vue"
import { constants } from "@/utils"
import { VariableValue } from "@/types/variable"

const dashboardStore = useDashboardStore()
const projectStore = useProjectStore()

const variantColumn: QTableColumn = {
   name: "variant",
   label: "Variant",
   align: "left",
   field: (row: VariantForRender) => row.variant,
   sortable: true,
}

const sessionsColumn: QTableColumn = {
   name: "sessions",
   label: "Sessions",
   align: "left",
   field: (row: VariantForRender) => row.sessions,
   sortable: true,
}
const conversionsColumn: QTableColumn = {
   name: "conversions",
   label: "Conversions",
   align: "left",
   field: (row: VariantForRender) => row.conversions,
   sortable: true,
}

const conversionRateColumnLabel = computed(() => {
   return `Conversion Rate`
})

const conversionRateColumn: QTableColumn = {
   name: "conversion-rate",
   label: conversionRateColumnLabel.value,
   align: "left",
   field: (row: VariantForRender) => row.conversionRate,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const trafficDistributionColumn: QTableColumn = {
   name: "traffic-distribution",
   label: "Traffic %",
   align: "left",
   field: (row: VariantForRender) => row.trafficPct,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const columns = computed(() => {
   const variables = dashboardStore.variable_snapshot
      ? dashboardStore.variable_snapshot.ids
      : []
   return [
      variantColumn,
      sessionsColumn,
      conversionsColumn,
      trafficDistributionColumn,
      conversionRateColumn,
      ...variables.map((vId) => {
         return <QTableColumn>{
            name: projectStore.getName(vId),
            label: projectStore.getName(vId),
            align: "left",
            field: (row: VariantForRender) => {
               return row.variables.find((x) => x.variableId == vId)?.value
            },
            sortable: true,
            style: "max-width: 400px; text-wrap: wrap;",
         }
      }),
   ]
})

const defaultConversionRate = computed(() => {
   const defaultConversionRates =
      dashboardStore.time_series?.default_conversion_rate
   if (!defaultConversionRates) {
      return 0
   }
   return defaultConversionRates[defaultConversionRates.length - 1]
})

const rows = computed(() => {
   const variantStats = dashboardStore.variable_snapshot?.variants
   if (!variantStats) {
      return []
   }
   let agg: VariantForRender[] = []
   variantStats.forEach((variantStat) => {
      const combinationNumber = variantStat.variant
      const variables = combinationNumber.split(",")
      const vars = variables.map((variable) => {
         const spl = variable.split(":")
         const id: number = +spl[0]
         const index: number = +spl[1]
         return <VariableValue>{
            variableId: id,
            humanReadableName: projectStore.getName(id),
            value: projectStore.getValue(id, index),
         }
      })
      const newComboForRender = <VariantForRender>{
         variant: combinationNumber,
         conversionRate: variantStat.conversion_rate,
         trafficPct: variantStat.traffic_pct,
         statSig: {
            lb: variantStat.cr_lb,
            ub: variantStat.cr_ub,
            defaultConversionRate: defaultConversionRate.value,
         },
         conversions: variantStat.conversions,
         sessions: variantStat.sessions,
         variables: vars,
      }

      agg.push(newComboForRender)
   })
   return agg
})
const activeVariableFilter = ref({
   humanReadableName: projectStore.variables.find(
      (x): boolean => dashboardStore.variable_snapshot?.ids[0] == x.id,
   )
      ? projectStore.variables.find(
           (x): boolean => dashboardStore.variable_snapshot?.ids[0] == x.id,
        )!.humanReadableName
      : "None Selected",
   id: dashboardStore.variable_snapshot?.ids[0],
})
const onVariableClick = async (id: number) => {
   activeVariableFilter.value = {
      humanReadableName: projectStore.variables.find((x): boolean => id == x.id)
         ? projectStore.variables.find((x): boolean => id == x.id)!
              .humanReadableName
         : "None Selected",
      id: id,
   }
   const daysBack = dashboardStore.daysBack
      ? dashboardStore.daysBack
      : constants.DEFAULT_DAYS_BACK
   const aggLevelForQuery = dashboardStore.agg_level
      ? dashboardStore.agg_level
      : constants.DEFAULT_AGG_LEVEL

   const variableIds = [id]
   await dashboardStore.getDashboard(daysBack, aggLevelForQuery, variableIds)
}
</script>
<style lang="scss" scoped>
#combinations-table-container {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 100%;
   .q-td {
      text-align: left;
   }
}
.toggles-filters-container {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   // border: 1px solid #e0e0e0;
   // border-radius: 5px;
}
#no-data {
   font-size: 1.25em;
   color: $primary;
   font-weight: bold;
   margin-left: 10px;
}
#no-data-container {
   min-height: 30vh;
}
#selector-container {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px;
}
</style>
