<template>
   <DashboardHeader />
   <div id="main" class="q-pa-md">
      <UnifiedGraphCard />
      <VariableSnapshotCard />
      <CombinationsTableV2 />
   </div>
</template>
<script setup lang="ts">
import { DashboardHeader } from "@components/index"
import { collectError, logInfo } from "@utils/index"
import { useDashboardStore } from "@stores/dashboardStore"
const dashboardStore = useDashboardStore()
import { constants } from "../../utils/index"
import UnifiedGraphCard from "./graphs/UnifiedGraphCard.vue"
import CombinationsTableV2 from "@components/combinations-table-v2/CombinationsTableV2.vue"
import VariableSnapshotCard from "@components/variable-snapshot/VariableSnapshotCard.vue"
import { watch } from "vue"
import { useProjectStore } from "@stores/projectStore"
const projectStore = useProjectStore()

try {
   if (!dashboardStore.stats) {
      logInfo("No summary stats found. Fetching dashboard data")
      await dashboardStore.getDashboard(
         constants.DEFAULT_DAYS_BACK,
         constants.DEFAULT_AGG_LEVEL,
         [],
      )
   } else {
      await dashboardStore.getDashboard(
         dashboardStore.daysBack!,
         dashboardStore.agg_level!,
         dashboardStore.variable_snapshot!.ids,
      )
   }
} catch (error) {
   collectError(error as Error)
}
watch(
   () => projectStore.projectId,
   async () => {
      if (projectStore.projectId) {
         projectStore.getVariablesByProjectId()
         dashboardStore.getDashboard(
            dashboardStore.daysBack!,
            dashboardStore.agg_level!,
         )
      }
   },
)
</script>
<style scoped lang="scss">
#main {
   min-height: 600px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}
</style>
