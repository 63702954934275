import axios, {
   AxiosInstance,
   AxiosResponse,
   InternalAxiosRequestConfig,
} from "axios"
import { clerk, getOrgId } from "./clerk"

import {
   DBVariable,
   Attribute,
   Project,
   ProjectSettings,
   UpdateProjectSettingsPayload,
   UpdateAttributePayload,
   CreateAttributePayload,
   SessionMetricsV2,
} from "../types"
import { EditableVariable } from "../types/variable"
import { SessionMetrics } from "@/types/sessionMetrics"
import { logInfo } from "@utils/logging"
import { useProjectStore } from "@stores/projectStore"
import { addBreadcrumb } from "./sentry"

const adminBackendClient: AxiosInstance = axios.create({
   baseURL: <string>import.meta.env.VITE_API_SERVER_URL,
   timeout: 1000,
})

adminBackendClient.interceptors.request.use(
   async (config: InternalAxiosRequestConfig) => {
      await clerk.load()
      const token = await clerk.session?.getToken()
      if (!token) {
         logInfo("No Clerk token found, redirecting to sign-in")
         window.location.href = "/sign-in"
      }
      addBreadcrumb({
         category: "API",
         message: `Calling Admin Backend API: ${config.url}`,
         level: "info",
      })
      if (config.headers) {
         config.headers.Authorization = `Bearer ${token}`
      }
      return config
   },
   (error) => {
      // TODO: Not sure if I should use a response interceptor
      console.log("Error in request interceptor")
      const s = error.response.status
      if (s === 401 || s === 403) {
         addBreadcrumb({
            category: "Auth",
            message: `Unauthorized: ${error.response.config.url}`,
            level: "error",
         })
         console.log("Unauthorized")
      }
   },
)

export default adminBackendClient

const getVariablesByProjectId = async (
   projectId: number,
): Promise<AxiosResponse<DBVariable[]>> => {
   const response = await adminBackendClient.get(
      `/variables/project/${projectId}`,
   )
   return response
}

const getVariable = async (id: number): Promise<AxiosResponse<DBVariable>> => {
   return await adminBackendClient.get(`/variables/${id}`)
}

const updateVariable = async (
   id: number,
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   return await adminBackendClient.patch(`/variables/${id}`, payload)
}

const deleteVariable = async (id: number): Promise<AxiosResponse<boolean>> => {
   return await adminBackendClient.delete(`/variables/${id}`)
}

const createVariable = async (
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   return await adminBackendClient.post(`/variables`, payload)
}

const getAttributesByProjectId = async (
   projectId: number,
): Promise<AxiosResponse<Attribute[]>> => {
   return await adminBackendClient.get(`/attributes/project/${projectId}`)
}

const getAttribute = async (id: number): Promise<AxiosResponse<Attribute>> => {
   return await adminBackendClient.get(`/attributes/${id}`)
}

const updateAttribute = async (
   id: number,
   payload: UpdateAttributePayload,
): Promise<AxiosResponse<Attribute>> => {
   return await adminBackendClient.patch(`/attributes/${id}`, payload)
}

const deleteAttribute = async (id: number): Promise<AxiosResponse<boolean>> => {
   return await adminBackendClient.delete(`/attributes/${id}`)
}

const createAttribute = async (
   payload: CreateAttributePayload,
): Promise<AxiosResponse<Attribute>> => {
   return await adminBackendClient.post(`/attributes`, payload)
}

const getProjectSettings = async (
   projectId: number,
): Promise<AxiosResponse<ProjectSettings>> => {
   return await adminBackendClient.get(`/project-settings/project/${projectId}`)
}

const updateProjectSettings = async (
   projectId: number,
   payload: UpdateProjectSettingsPayload,
): Promise<AxiosResponse<ProjectSettings>> => {
   return await adminBackendClient.patch(
      `/project-settings/project/${projectId}`,
      payload,
   )
}

const getProjectsByOrgId = async (
   orgId: string,
): Promise<AxiosResponse<Project[]>> => {
   return await adminBackendClient.get(`/projects/organization/${orgId}`)
}

const getSessionMetricsByProjectId = async (
   projectId: number,
   startDate: number,
   endDate: number,
   aggLevel: string,
): Promise<AxiosResponse<SessionMetrics>> => {
   const response = await adminBackendClient.get<SessionMetrics>(
      `/session-stats/project/${projectId}`,
      {
         params: {
            start_date: startDate,
            end_date: endDate,
            agg_level: aggLevel,
         },
      },
   )
   return response
}

const getDashboardByProjectId = async (
   projectId: number,
   startDate: number,
   endDate: number,
   aggLevel: string,
   variableIds?: number[],
): Promise<AxiosResponse<SessionMetricsV2>> => {
   const params = {
      start_date: startDate,
      end_date: endDate,
      agg_level: aggLevel,
      variable_id: variableIds,
   }
   const response = await adminBackendClient.get<SessionMetricsV2>(
      `/dashboard/project/${projectId}`,
      {
         params: params,
         paramsSerializer: {
            indexes: null, // no brackets at all
         },
      },
   )
   return response
}

const createProject = async (
   humanReadableName: string,
   orgId: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.post(`/projects`, {
      humanReadableName: humanReadableName,
      organizationId: orgId,
   })
}

const deleteProject = async (
   projectId: number,
): Promise<AxiosResponse<boolean>> => {
   return await adminBackendClient.delete(`/projects/${projectId}`)
}

const updateProject = async (
   projectId: number,
   orgId: string,
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.patch(`/projects/${projectId}`, {
      organizationId: orgId,
      humanReadableName: humanReadableName,
   })
}

const updateCurrentProject = async (
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   const projectStore = useProjectStore()
   const orgId = await getOrgId()
   const id = projectStore.projectId
   if (!id) throw new Error("No project ID found")
   return await adminBackendClient.patch(`/projects/${id}`, {
      organizationId: orgId,
      humanReadableName: humanReadableName,
   })
}

export {
   adminBackendClient,
   getVariable,
   getAttribute,
   getVariablesByProjectId,
   getAttributesByProjectId,
   updateAttribute,
   deleteAttribute,
   createAttribute,
   updateVariable,
   deleteVariable,
   createVariable,
   getProjectSettings,
   updateProjectSettings,
   getProjectsByOrgId,
   getSessionMetricsByProjectId,
   getDashboardByProjectId,
   createProject,
   deleteProject,
   updateProject,
   updateCurrentProject,
}
