<template>
   <QCard class="kpi-card">
      <QCardSection class="text-left">
         <p>
            Additional Conversions:
            {{ additionalConversions }}
         </p>
         <p>
            Cumulative Conversions:
            {{ cumulativeConversions }}
         </p>
         <p>
            The number of additional sessions in which at least one conversion
            occurred on your site
         </p>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useDashboardStore } from "../../../stores/dashboardStore"
const dashboardStore = useDashboardStore()

const additionalConversions = computed(() => {
   const raw = dashboardStore.stats?.addl_conversions
   if (!raw) {
      return "No Data"
   }
   return Math.round(raw)
})
const cumulativeConversions = computed(() => {
   const raw = dashboardStore.stats?.total_addl_conversions
   if (!raw) {
      return "No Data"
   }
   return Math.round(raw)
})
</script>
<style scoped lang="scss">
.kpi-card {
   width: 100%;
}
</style>
