<template>
   <QBtnDropdown color="primary" :label="timeSelectionLabel">
      <QList>
         <QItem clickable v-close-popup @click="selectTimeRange(30)">
            <QItemSection>
               <QItemLabel>Last 30 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(14)">
            <QItemSection>
               <QItemLabel>Last 14 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(7)">
            <QItemSection>
               <QItemLabel>Last 7 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(3)">
            <QItemSection>
               <QItemLabel>Last 3 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(1)">
            <QItemSection>
               <QItemLabel>Yesterday</QItemLabel>
            </QItemSection>
         </QItem>
      </QList>
   </QBtnDropdown>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useSessionMetricsStore } from "../stores/sessionMetricsStore"
const sessionMetricsStore = useSessionMetricsStore()

const timeSelectionsMap = new Map([
   [30, "30 Days"],
   [14, "14 Days"],
   [7, "7 Days"],
   [3, "3 Days"],
   [1, "Yesterday"],
] as [number, string][])

const timeSelectionLabel = computed(() => {
   if (sessionMetricsStore.daysBack) {
      return timeSelectionsMap.get(sessionMetricsStore.daysBack)
   } else {
      return "Loading..."
   }
})

const selectTimeRange = async (daysBack: number) => {
   const aggLevel = sessionMetricsStore.agg_level!
   await sessionMetricsStore.getSessionMetrics(daysBack, aggLevel)
}
</script>
