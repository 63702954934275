<template>
   <div id="combinations-table-container">
      <QCard>
         <QCardSection class="bg-primary q-pa-sm">
            <div class="text-h6 text-white">Combinations</div>
         </QCardSection>
         <QCardSection>
            <div class="toggles-filters-container">
               <QCheckbox
                  size="sm"
                  v-model="onlyShowStatSig"
                  :val="true"
                  label="Stat Sig Only"
               />
            </div>
            <QTable
               id="combinations-table"
               v-if="
                  sessionMetricsStore.summary_stats?.variants &&
                  projectStore.variables
               "
               dense
               :rows="rows"
               :columns="columns"
               row-key="id"
               width="100%"
               :pagination="{
                  page: 1,
                  rowsPerPage: 10,
                  descending: false,
               }"
               flat
            >
               <template v-slot:header-cell-conversion-rate="props">
                  <QTh :props="props">
                     {{ conversionRateColumnLabel }}
                  </QTh>
               </template>
               <template v-slot:header-cell-conversion-rate-improvement="props">
                  <QTh :props="props">
                     {{ improvementColumnLabel }}
                  </QTh>
               </template>
               <template v-slot:header-cell-statistical-significance="props">
                  <QTh :props="props">
                     {{ statSigColumnLabel }}
                  </QTh>
               </template>
               <template
                  v-slot:body-cell-statistical-significance="props: unknown"
               >
                  <QTd>
                     <StatSigRenderer
                        :val="(props as QTableStatSigPropPatch).value"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell-combination-number="props: unknown">
                  <QTd>
                     <CombinationNumberRenderer
                        :val="(props as QTableCombinationNumberPropPatch).value"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell="props: unknown">
                  <QTd
                     ><TruncatedRenderer
                        :val="
                           String((props as QTableTruncatedPropPatch).value)
                        "
                  /></QTd>
               </template>
               <template v-slot:no-data="{ icon, filter }">
                  <div
                     id="no-data-container"
                     class="full-width row flex-center text-accent q-ma-lg"
                  >
                     <QIcon
                        size="2em"
                        color="primary"
                        :name="filter ? 'filter_b_and_w' : icon"
                     />
                     <span id="no-data"
                        >No data match your query. Please change your filters
                        and try again.
                     </span>
                  </div>
               </template>
            </QTable>
            <CombinationsTablePlaceholder v-else />
         </QCardSection>
      </QCard>
   </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { QTable, QTableColumn, QTableProps } from "quasar"
import { useSessionMetricsStore } from "@stores/sessionMetricsStore"
import { useProjectStore } from "@stores/projectStore"
import CombinationsTablePlaceholder from "./CombinationsTablePlaceholder.vue"
import {
   CombinationForRender,
   StatSigForRender,
} from "../../types/combinations-table"
import TruncatedRenderer from "./TruncatedRenderer.vue"
import StatSigRenderer from "./StatSigRenderer.vue"
import CombinationNumberRenderer from "./CombinationNumberRenderer.vue"
import { formatPercentTwoDecimals } from "./formatters"

const sessionMetricsStore = useSessionMetricsStore()
const projectStore = useProjectStore()

const onlyShowStatSig = ref(false)

type QTableStatSigPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: StatSigForRender
}
type QTableTruncatedPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableCombinationNumberPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string
}

const lookBackWindowDays = computed(() => {
   if (!sessionMetricsStore.daysBack) {
      return 180
   }
   return sessionMetricsStore.daysBack + 180
})

const combinationNumberColumn: QTableColumn = {
   name: "combination-number",
   label: "#",
   align: "left",
   field: (row: CombinationForRender) => row.number,
   sortable: true,
}

const sessionsColumn: QTableColumn = {
   name: "sessions",
   label: "Sessions",
   align: "left",
   field: (row: CombinationForRender) => row.sessions,
   sortable: true,
}
const conversionsColumn: QTableColumn = {
   name: "conversions",
   label: "Conversions",
   align: "left",
   field: (row: CombinationForRender) => row.conversions,
   sortable: true,
}

const conversionRateColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day Conversion Rate`
})

const conversionRateColumn: QTableColumn = {
   name: "conversion-rate",
   label: conversionRateColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRate,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const improvementColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day Improvement`
})

const conversionRateImprovementColumn: QTableColumn = {
   name: "conversion-rate-improvement",
   label: improvementColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRateImprovement,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const statSigColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day 95% Confidence Interval`
})

const statSigColumn: QTableColumn = {
   name: "statistical-significance",
   label: statSigColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.statSig,
   sortable: true,
   sort: (a: StatSigForRender, b: StatSigForRender) =>
      (a.ub - a.defaultConversionRate) / (a.ub - a.lb) -
      (b.ub - b.defaultConversionRate) / (b.ub - b.lb),
}
const trafficDistributionColumn: QTableColumn = {
   name: "traffic-distribution",
   label: "Traffic %",
   align: "left",
   field: (row: CombinationForRender) => row.trafficDistribution,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const columns = computed(() => {
   const variables = projectStore.variables
   return [
      combinationNumberColumn,
      sessionsColumn,
      conversionsColumn,
      trafficDistributionColumn,
      conversionRateColumn,
      conversionRateImprovementColumn,
      statSigColumn,
      ...variables.map((variable) => {
         return <QTableColumn>{
            name: variable.humanReadableName,
            label: variable.humanReadableName,
            align: "left",
            field: (row: CombinationForRender) =>
               row[variable.humanReadableName],
            sortable: true,
            style: "max-width: 400px; text-wrap: wrap;",
         }
      }),
   ]
})

const calculateConversionRateImprovement = (
   combinationConversionRate: number,
   defaultConversionRate: number,
) => {
   return (
      (combinationConversionRate - defaultConversionRate) /
      defaultConversionRate
   )
}

const defaultConversionRate = computed(() => {
   return sessionMetricsStore.summary_stats?.variants?.["0"]!.conversion_rate
})

const rows = computed(() => {
   const combinationStats = sessionMetricsStore.summary_stats?.variants
   if (!combinationStats) {
      return []
   }
   const combinationNumbers = Object.keys(combinationStats) // ['0', '1'...]
   let agg: CombinationForRender[] = []
   combinationNumbers.forEach((combinationNumber) => {
      const combo = projectStore.decodeCombination(Number(combinationNumber))
      const newComboForRender = <CombinationForRender>{
         number: parseInt(combinationNumber),
         conversionRate: combinationStats[combinationNumber].conversion_rate,
         trafficDistribution:
            combinationStats[combinationNumber].sessions /
            sessionMetricsStore.summary_stats!.sessions,
         conversionRateImprovement: calculateConversionRateImprovement(
            combinationStats[combinationNumber].conversion_rate,
            defaultConversionRate.value!,
         ),
         statSig: {
            lb: combinationStats[combinationNumber].lb,
            ub: combinationStats[combinationNumber].ub,
            defaultConversionRate: defaultConversionRate.value,
         },
         conversions: combinationStats[combinationNumber].conversions,
         sessions: combinationStats[combinationNumber].sessions,
      }
      combo.forEach((variable) => {
         newComboForRender[variable.humanReadableName] = variable.value
      })

      agg.push(newComboForRender)
   })
   if (onlyShowStatSig.value) {
      agg = agg.filter(
         (combo) => combo.statSig.lb > defaultConversionRate.value!,
      )
   }
   return agg
})
</script>
<style lang="scss" scoped>
#combinations-table-container {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 100%;
   .q-td {
      text-align: left;
   }
}
.toggles-filters-container {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   // border: 1px solid #e0e0e0;
   // border-radius: 5px;
}
#no-data {
   font-size: 1.25em;
   color: $primary;
   font-weight: bold;
   margin-left: 10px;
}
#no-data-container {
   min-height: 30vh;
}
</style>
