<template>
   <span>
      {{ comboNumber }}
   </span>
</template>
<script setup lang="ts">
import { computed, PropType } from "vue"

const props = defineProps({
   val: {
      type: Number as PropType<string | number>,
      required: true,
   },
})
const comboNumber = computed(() => {
   if (props.val === "0" || props.val === 0) {
      return "Default"
   } else {
      return props.val
   }
})
</script>
