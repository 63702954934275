<template>
   <div>
      <div class="chart-container">
         <Doughnut
            id="chart"
            type="doughnut"
            ref="doughnut"
            height="200px"
            fullWidth
            :options="chartOptions"
            :data="chartData"
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, onUnmounted, VNodeRef, computed } from "vue"
import { Doughnut } from "vue-chartjs"
import { useDashboardStore } from "../../stores/dashboardStore"

import {
   Chart as ChartJS,
   Tooltip,
   ChartData,
   PointElement,
   TimeScale,
   LinearScale,
   ChartOptions,
   Filler,
   Legend,
   Title,
   ArcElement,
} from "chart.js"
import { getRetroMetroColor } from "@utils/chartColors"

ChartJS.register(
   Tooltip,
   TimeScale,
   ArcElement,
   LinearScale,
   PointElement,
   Filler,
   Legend,
   Title,
)

const dashboardStore = useDashboardStore()

const chartOptions: Ref<ChartOptions<"doughnut">> = computed(() => ({
   responsive: true,
   interaction: {
      mode: "index",
      intersect: false,
   },
   plugins: {
      filler: {
         drawTime: "beforeDatasetsDraw",
         propagate: true,
      },
      title: {
         display: true,
         text: "Variant Serving Rates",
      },
      legend: {
         display: true,
         position: "left",
         maxWidth: 150,
         labels: {
            boxWidth: 10,
         },
         onHover: function () {
            // Don't allow filtering
            return
         },
         onLeave: function () {
            // Don't allow filtering
            return
         },
      },
   },
}))

const chartData: Ref<ChartData<"doughnut">> = ref({
   labels: [],
   datasets: [],
})

const updateChartData = () => {
   let data = dashboardStore.variable_snapshot!.variants.map((x) => {
      return x.traffic_pct * 100
   })
   let colors = dashboardStore.variable_snapshot!.variants.map((x, i) => {
      return getRetroMetroColor(i)
   })

   chartData.value = {
      labels: dashboardStore.variable_snapshot?.variants.map((x) => {
         return x.variant
      }),
      datasets: [
         {
            data: data,
            backgroundColor: colors,
         },
      ],
   }
}
updateChartData()

const doughnut: VNodeRef = ref(null)

const handleResize = () => {
   if (!doughnut.value) return
   doughnut.value.chart.resize()
}

onMounted(() => {
   window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
   window.removeEventListener("resize", handleResize)
})

dashboardStore.$subscribe(() => {
   updateChartData()
   doughnut.value.chart.update()
})
</script>
<style lang="scss" scoped>
.chart-container {
   position: relative;
   height: auto;
   width: 100%;
   margin: auto;
}
#chart {
   position: relative;
   height: 100%;
   width: 100%;
}
</style>
