<template>
   <QCard class="kpi-card">
      <QCardSection class="text-left">
         <p>Additional Sessions: {{ additionalSessions }}</p>
         <p>
            Cumulative Sessions:
            {{ cumulativeSessions }}
         </p>
         <p>The number of user sessions on your site</p>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useDashboardStore } from "../../../stores/dashboardStore"
const dashboardStore = useDashboardStore()

const additionalSessions = computed(() => {
   const raw = dashboardStore.stats?.sessions
   if (!raw) {
      return "No Data"
   }
   return Math.round(raw!)
})
const cumulativeSessions = computed(() => {
   const raw = dashboardStore.stats?.total_sessions
   if (!raw) {
      return "No Data"
   }
   return Math.round(raw!)
})
</script>
<style scoped lang="scss">
.kpi-card {
   width: 100%;
}
</style>
