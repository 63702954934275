<template>
   <QCard class="kpi-card">
      <QCardSection class="text-left">
         <p>
            Additional Revenue:
            {{ additionalRevenue }}
         </p>
         <p>
            Cumulative Revenue:
            {{ cumulativeRevenue }}
         </p>
         <p>The amount of additional revenue generated on your site</p>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed } from "vue"

const additionalRevenue = computed(() => {
   return "Metric coming soon"
})
const cumulativeRevenue = computed(() => {
   return "Metric coming soon"
})
</script>
<style scoped lang="scss">
.kpi-card {
   width: 100%;
}
</style>
