import { defineStore } from "pinia"
import { getDashboardByProjectId } from "@/services/adminBackendClient"
import { useProjectStore } from "./projectStore"
import { toast } from "vue3-toastify"
import { collectError } from "@utils/errors"
import {
   SessionMetricsV2,
   SessionStatsV2,
   TimeBucketV2,
   TopN,
   VariableSnapshot,
} from "@/types/dashboard"
import dashboard from "@/mocks/dashboard"

type DashboardState = {
   loading: boolean
   trace_id?: string
   start_date?: number
   end_date?: number
   as_of_time?: number
   agg_level?: string
   stats?: SessionStatsV2
   topN?: TopN
   variable_snapshot?: VariableSnapshot
   time_series?: TimeBucketV2
}

const getDaysBack = (startDate: number) => {
   const now = Math.floor(new Date().getTime() / 1000)
   return Math.floor((now - Number(startDate)) / (24 * 60 * 60))
}

export const useDashboardStore = defineStore("dashboardStore", {
   state: (): DashboardState => {
      return {
         loading: false,
         trace_id: undefined,
         start_date: undefined,
         end_date: undefined,
         as_of_time: undefined,
         agg_level: undefined,
         stats: undefined,
         topN: undefined,
         variable_snapshot: undefined,
         time_series: undefined,
      }
   },
   persist: true,
   actions: {
      getStartDate(daysBack: number) {
         const now = new Date()
         now.setDate(now.getDate() - daysBack)
         now.setUTCHours(0, 0, 0, 0)
         // convert now to epoch time
         return now.getTime() / 1000
      },
      getEndDate(): number {
         // yesterday at 11:59:59 PM UTC
         const now = new Date()
         now.setDate(now.getDate() - 1)
         now.setUTCHours(23, 59, 59, 0)
         // convert now to epoch time
         return now.getTime() / 1000
      },
      async getDashboard(
         daysBack: number,
         aggLevel: string,
         variableIds?: number[],
      ): Promise<SessionMetricsV2> {
         this.loading = true
         const projectStore = useProjectStore()
         const startDate = this.getStartDate(daysBack)
         const endDate = this.getEndDate()
         const projectId = projectStore.projectId
         if (!projectId) throw new Error("Project ID is not set")
         let response = null
         try {
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: dashboard }
            } else {
               response = await getDashboardByProjectId(
                  projectId,
                  startDate,
                  endDate,
                  aggLevel,
                  variableIds,
               )
            }
         } catch (error) {
            collectError(error as Error)
            this.loading = false
            toast.error("Error occurred while fetching session metrics")
            throw error
         }
         this.trace_id = response.data.trace_id
         this.start_date = response.data.start_date
         this.end_date = response.data.end_date
         this.as_of_time = response.data.as_of_time
         this.agg_level = response.data.agg_level
         this.time_series = response.data.time_series
         this.stats = response.data.stats
         this.topN = response.data.topN
         this.variable_snapshot = response.data.variable_snapshot
         this.loading = false

         return response.data
      },
   },
   getters: {
      daysBack(state): number | undefined {
         if (state.start_date) {
            return getDaysBack(state.start_date)
         } else {
            return undefined
         }
      },
      hasVariants(state): boolean {
         return (
            state.topN?.combinations !== null &&
            state.topN?.combinations !== undefined
         )
      },
   },
})
