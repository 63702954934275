<template>
   <NTileVsDefaultConversionRateAbsoluteGraph v-if="!props.showRelative" />
   <NTileVsDefaultConversionRateRelativeGraph
      v-else
   ></NTileVsDefaultConversionRateRelativeGraph>
</template>
<script setup lang="ts">
import NTileVsDefaultConversionRateAbsoluteGraph from "./NTileVsDefaultConversionRateAbsoluteGraph.vue"
import NTileVsDefaultConversionRateRelativeGraph from "./NTileVsDefaultConversionRateRelativeGraph.vue"
const props = defineProps({
   showRelative: {
      type: Boolean,
      required: true,
   },
})
</script>
